import PropTypes from 'prop-types';
import React, { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import Button from '@mui/material/Button';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import ThemeButton from 'ui-component/ThemeButton';
// assets
import { IconMenu2, IconBrandWechat, IconX, IconInfoCircle } from '@tabler/icons-react';
import WxQrCode from 'assets/images/wx_qrcode.svg';
import QqQrCode from 'assets/images/qq_qrcode.svg';

// 定义一个动态的Tooltip样式
const DynamicTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.background.paper,
    color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.text.primary,
  },
}));

const Header = ({ handleLeftDrawerToggle }) => {
  // 使用 useTheme 钩子获取当前的主题
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* logo & toggler button */}
      <Box sx={{ width: 228, display: 'flex', [theme.breakpoints.down('md')]: { width: 'auto' } }}>
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              transition: 'all .2s ease-in-out',
              '&:hover': {
                background: theme.palette.secondary.dark,
                color: theme.palette.secondary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* WeChat Icon Button with Tooltip */}
      <DynamicTooltip title="联系我们" placement="bottom">
        {/* 
          使用 sx 属性来动态设置按钮的背景色和文本颜色，
          这些颜色基于当前的主题，
          当主题改变时，颜色会自动改变
        */}
        <ButtonBase
          onClick={handleClickOpen}
          sx={{
            borderRadius: '10px',
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            width: 'auto',
            padding: theme.spacing(0.6, 1.2),
            transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
            backgroundColor: theme.palette.mode === 'light' 
             ? theme.palette.primary.light 
             : theme.palette.secondary.light,
            color: theme.palette.mode === 'light'
             ? theme.palette.primary.main
             : theme.palette.secondary.main,
            '&:hover': {
            backgroundColor: theme.palette.mode === 'light'
             ? theme.palette.primary.main
             : theme.palette.secondary.main,
            color: theme.palette.mode === 'light'
             ? theme.palette.primary.contrastText
             : theme.palette.secondary.contrastText,
            },
            '&:active': {
            backgroundColor: theme.palette.mode === 'light'
             ? theme.palette.primary.dark
             : theme.palette.secondary.dark,
            color: theme.palette.mode === 'light'
             ? theme.palette.primary.contrastText
             : theme.palette.secondary.contrastText,
            },
          }}
        >
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              ...theme.typography.menuButton,
              marginRight: theme.spacing(0.5), // 调整图标和文字之间的间距
              backgroundColor: 'transparent', // 使Avatar背景透明
              color: 'inherit', // 使Avatar颜色继承CustomButtonBase的颜色
              fontSize: '0.875rem', // 调整图标大小
            }}
            color="inherit"
          >
            <IconBrandWechat stroke={1.5} size="1.3rem" />
          </Avatar>
          <Typography variant="body3" sx={{ color: 'inherit', transition: 'color 0.1s ease-in-out' }}>
            联系客服
          </Typography>
          <Box sx={{ flexGrow: 1 }} /> {/* 增加一个空的Box组件来增加右边距 */}
        </ButtonBase>
      </DynamicTooltip>

      {/* Theme Button with Tooltip */}
      <DynamicTooltip title="切换主题" placement="bottom">
        <Box sx={{ marginLeft: theme.spacing(1) }}> {/* 增加两个按钮之间的间距 */}
          <ThemeButton />
        </Box>
      </DynamicTooltip>
      <ProfileSection />

      {/* Dialog for WeChat and QQ QR Codes */}
      <Dialog open={open} onClose={handleClose} sx={{ '& .MuiDialog-paper': { padding: 2 } }}>
        <DialogTitle>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconInfoCircle style={{ marginRight: 8 }} />
            <Typography variant="h5">联系我们</Typography> {/* 加大一号 */}
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: theme.palette.grey[500],
            }}
          >
            <IconX />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                扫一扫，添加微信客服
              </Typography>
              <img src={WxQrCode} alt="WeChat QR Code" style={{ width: '100%', maxWidth: '200px' }} />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ marginBottom: 1 }}>
                扫一扫，添加QQ客服
              </Typography>
              <img src={QqQrCode} alt="QQ QR Code" style={{ width: '100%', maxWidth: '200px' }} />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="contained" sx={{ borderRadius: '8px', fontSize: '1rem' }}>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
