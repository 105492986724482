// assets
import {
  IconDashboard,
  IconArticle,
  IconSquareKey,
  IconCoinYen,
  IconUserScan,
  IconInfoSquareRounded,
  IconRecharging,
  IconVip,
  IconBrandGoogleBigQuery,
  IconAugmentedReality, 
} from '@tabler/icons-react';

// constant
const icons = { IconDashboard, IconArticle, IconSquareKey, IconAugmentedReality, IconCoinYen, IconUserScan, IconRecharging, IconVip, IconInfoSquareRounded, IconBrandGoogleBigQuery };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const panel = {
  id: '/',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: '数据总览',
      type: 'item',
      url: '/dashboard',
      icon: icons.IconDashboard,
      breadcrumbs: false,
      isAdmin: false
    },
    //{
    //  id: 'activity',
    //  title: '最新活动',
    //  type: 'item',
    //  url: '/activity',
    //  icon: icons.IconRecharging,
    //  breadcrumbs: false
    //},
    {
      id: 'token',
      title: '令牌管理',
      type: 'item',
      url: '/token',
      icon: icons.IconSquareKey,
      breadcrumbs: false
    },
    {
      id: 'log',
      title: '消费日志',
      type: 'item',
      url: '/log',
      icon: icons.IconBrandGoogleBigQuery,
      breadcrumbs: false
    },
    //{
    //  id: 'model',
    //  title: '模型定价',
    //  type: 'item',
    //  url: '/model',
    //  icon: icons.IconAugmentedReality,
    //  breadcrumbs: false
    //},
    //{
    //  id: 'mjlog',
    //  title: 'MJ绘画',s
    //  type: 'item',
    //  url: '/mjlog',
    //  icon: icons.IconArticle,
    //  breadcrumbs: false
    //},
    {
      id: 'topup',
      title: '充值中心',
      type: 'item',
      url: '/topup',
      icon: icons.IconCoinYen,
      breadcrumbs: false
    },
    //{
    //  id: 'partner',
    //  title: '成为代理',
    //  type: 'item',
    //  url: '/partner',
    //  icon: icons.IconVip,
    //  breadcrumbs: false
    //},
    {
      id: 'about',
      title: '新手指南',
      type: 'item',
      url: '/about',
      icon: icons.IconInfoSquareRounded,
      breadcrumbs: false
    },
    {
      id: 'profile',
      title: '帐户设置',
      type: 'item',
      url: '/profile',
      icon: icons.IconUserScan,
      breadcrumbs: false,
      isAdmin: false
    }
  ]
};

export default panel;
