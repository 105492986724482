// material-ui
import logo from 'assets/images/logo.svg';
import logo2 from 'assets/images/logo-2.svg'; // 导入新的logo图片
import { useSelector } from 'react-redux';

const Logo = ({ variant }) => {
  const siteInfo = useSelector((state) => state.siteInfo);

  // 根据variant来决定使用哪个logo
  const logoUrl = variant === 'auth' ? logo2 : siteInfo.logo || logo; // 如果variant为'auth'，则使用logo2，否则使用siteInfo.logo或默认logo

  // 根据variant来决定图片的宽度
  const logoWidth = variant === 'auth' ? "80" : "155"; // 如果variant为'auth'，则宽度设置为80，否则设置为155

  return <img src={logoUrl} alt={siteInfo.system_name} width={logoWidth} />;
};

export default Logo;

// 原始LOGO组件代码
// import logo from 'assets/images/logo.svg';
// import { useSelector } from 'react-redux';
// 
// const Logo = () => {
//   const siteInfo = useSelector((state) => state.siteInfo);

//   return <img src={siteInfo.logo || logo} alt={siteInfo.system_name} width="165" />;
// };
// 
// export default Logo;
